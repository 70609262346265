<template>
  <div class="rule-page">
    <Head title="拍卖规则" />
    <Warpper top="40">
      <iframe :src="ihtml" ref="iframe" class="rule-iframe" frameborder="0"></iframe>
      <!-- <Container top="0">
        <div class="rule-warpper">
          <div class="rule-content" v-html="ihtml">
            <h5>
              <span>规则详情</span>
            </h5>
            <ul>
              <li>1.所有拍品起拍价均为1元，每次加价幅度为10元，不设底价，拍价不含邮费；</li>
              <li>2.有问题请留言加客服微信lakelandbook或者电话咨询15621008116；</li>
              <li>3.湖畔书店的竞标实行"5分钟延时制"，即在竞标预定结束时间后的5分钟内，如果有用户继续出价，则从出价时刻起顺次延长5分钟，直至5分钟无人继续竞标，则该竞标结束。延时期间，结束时限显示为红色，直至竞标结束。</li>
              <li>4.拍卖结束后，我店会生成订单并合并运费；</li>
              <li>5.订单结算期限是订单生成之后7日内（订单日期+7=结算最后日期）；</li>
              <li>6 为保证出价公平公正，参拍需缴纳100元参拍保证金，如果产生违约情况（订单超过7天未付款等情况）将从保证金扣除订单金额的15%，若要参与下次竞拍需重新补齐保证金, 无故取消订单2次以上者，将退还剩余押金，谢绝参拍.</li>
              <li>7.保证金说明：用户登陆后点击右下角个人中心进行充值，在订单完成或者不存在任何违约情况下，如需退还保证金请联系客服。</li>
              <li>8.支付问题：本网站默认使用微信支付，如需支付宝、银行转账请联系客服。</li>
              <li>
                9代理价功能使用帮助：
                1.“使用代理出价”是指您可以把您对此书的心理价位填入相应的空格中，如果有人所出心理价低于您所设置的“最高心理价”，则系统会代您自动加价，如果有人超过了您的“最高心理价位”，则只显示您的“最高心理价位”及新的最高价；
                <br />2.“修改代理出价”是指您可以在拍卖结束之前（实际出价未到达您设置的最高价）可以修改您的“最高心理价”，调高或者调低；
                <br />3.“取消代理出价”是指您可以在拍卖结束之前（实际出价未到达您设置的最高价）可以取消您的“最高心理价”（此前已经成功出价的无法修改）；
                <br />4.如果您设置的“代理价”恰巧与其他用户预设的“最高代理价”相同，先设置者领先，后设置者出局；
                <br />5.如果您设置了“代理价”，其他用户恰巧手工出价金额与您的“代理价”则两个出价同时显示，但是先设置者（代理价）领先，后出价者出局；
                <br />
              </li>
              <li>
                10.本网站的联系方式：客服微信lakelandbook,手机号15621008116，微信二维码如下:
                <br />
                <img src="../../assets/images/wechat.png" alt="wechat" />
              </li>
            </ul>
          </div>
        </div>
      </Container> -->
    </Warpper>
  </div>
</template>

<script>
import { reqRule,reqRulePath } from "@/network/api";
export default {
  name: "rule",
  data(){
    return {
      ihtml:reqRulePath
    }
  },
  mounted(){
    
  },
  methods:{
    getRule(){
      reqRule().then(res=>{
        this.ihtml = res;
      }).catch (error=>{
        this.$toast({message:'系统错误'});
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.rule-iframe{
  width: 100%;
  height: 100%;
}
.rule-page,
.rule-warpper {
  background: #5cbfb0;
}
.rule-warpper {
  padding-top: 0.46rem;
  box-sizing: border-box;
  padding-bottom: 0.46rem;
}
.rule-content {
  width: 6.6rem;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0.1rem 0px 0px rgba(0, 95, 81, 0.12);
  margin: 0 auto;
  padding: 0.49rem 0.48rem;
  box-sizing: border-box;
  h5 {
    position: relative;
    text-align: center;
    margin-bottom: 0.42rem;
    span {
      font-size: 0.32rem;
      line-height: 0.32rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #5cbfb0;
      position: relative;
      &::before {
        content: "";
        height: 0.02rem;
        background: #5cbfb0;
        position: absolute;
        top: 50%;
        width: 1.7rem;
        margin-top: -0.01rem;
        left: -2rem;
      }
      &::after {
        content: "";
        height: 0.02rem;
        background: #5cbfb0;
        position: absolute;
        top: 50%;
        width: 1.7rem;
        margin-top: -0.01rem;
        right: -2rem;
      }
    }
  }
  li {
    line-height: 0.48rem;
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    img {
      width: 3.14rem;
      height: 3.14rem;
      object-fit: cover;
      margin: 0 auto;
      display: block;
    }
  }
}
</style>